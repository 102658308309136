.dataScreen_main {
  display: flex;
  width: 100%;
  max-height: 92%;
}

.videoPanel {
  /* display: flex; */
  /* flex-direction: column; */
  width: 66.67%;
}

.videoPanel video {
  width: 100%;
  max-height: 70%;
}

.floating2Collection {
  display: flex;
  position: absolute;
  left: 8px;
  top: 60px;
  background-color: rgba(0, 0, 0, 0.3);
}

.collectionVideoTitle {
  display: flex;
  font-size: 16px;
  color: white;
  margin: 0 5px;
}

.infoPanel {
  width: 33.33%;
  overflow-y: scroll;
}

.controlPanel {
  display: flex;
  height: 150px;
}

.messageSent {
  background-color: gainsboro;
  padding: 8px;
  width: 50%;
  position: relative;
  overflow-y: scroll;
}

.inputBar {
  padding: 8px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.inputButton {
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.photo {
  width: 100%;
  display: flex;
}

.infoPanel img {
  width: 50%;
  max-height: 200px;
}
