.card {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card span {
  font-size: 24px;
  padding: 4px;
}

.card img {
  width: 45px;
  height: 45px;
  padding: 0;
}
