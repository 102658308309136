body {
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}
.logobox {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
}
.logoutbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
}
.logoimg {
  width: 88px;
  height: 22px;
  margin-left: 25px;
}
