.dataScreen {
  position: relative;
  top: 0;
  left: 0;
  transform-origin: left top;
}

.dataScreen_layer1 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
}

.dataScreen_layer2 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  z-index: 2;
}

.dataScreen_layer2 video {
  width: 100%;
  max-height: 100%;
}

.dataScreen_layer3 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  z-index: 3;
}

.floatingLayer {
  width: 25%;
  height: 25%;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  visibility: visible;
  font-size: 25px;
}

.dataScreen_layer1 video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.floating2 {
  position: absolute;
  left: 0;
  top: 0;
  visibility: visible;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.floating2_div {
  display: flex;
  width: 256px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 8px;
  top: 8px;
}

.floating2Collection {
  display: flex;
  position: absolute;
  left: 8px;
  top: 75px;
  background-color: rgba(0, 0, 0, 0.3);
}

.collectionVideoTitle {
  display: flex;
  font-size: 16px;
  color: white;
  margin: 0 5px;
}

.floating2_div1 {
  position: relative;
}

.floating2_div1 img {
  width: 64px;
  height: 64px;
}

.floating2_div2 {
  height: 100%;
}

.floating2_div2 .span1 {
  display: flex;
  width: 192px;
  height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  color: white;
}

.floating2_div2 .span2 {
  display: flex;
  width: 192px;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
}

.floatingLayer input {
  width: 120px;
  height: 45px;
  line-height: 45px;
  background: rgba(0, 22, 33, 0.6);
  color: white;
  position: relative;
  border-radius: 15%;
  font-size: large;
}
