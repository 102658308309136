.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-image: url("https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr");
  background-size: cover;
}

.content {
  flex: 1;
  padding: 80px 0;
}

.form {
  margin: 30px 0;
}
